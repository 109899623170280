exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-time-stats-favourite-opponents-js": () => import("./../../../src/pages/all-time-stats/favourite-opponents.js" /* webpackChunkName: "component---src-pages-all-time-stats-favourite-opponents-js" */),
  "component---src-pages-all-time-stats-games-vs-each-other-js": () => import("./../../../src/pages/all-time-stats/games-vs-each-other.js" /* webpackChunkName: "component---src-pages-all-time-stats-games-vs-each-other-js" */),
  "component---src-pages-all-time-stats-home-away-stats-js": () => import("./../../../src/pages/all-time-stats/home-away-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-home-away-stats-js" */),
  "component---src-pages-all-time-stats-league-rankings-js": () => import("./../../../src/pages/all-time-stats/league-rankings.js" /* webpackChunkName: "component---src-pages-all-time-stats-league-rankings-js" */),
  "component---src-pages-all-time-stats-league-rankings-top-js": () => import("./../../../src/pages/all-time-stats/league-rankings-top.js" /* webpackChunkName: "component---src-pages-all-time-stats-league-rankings-top-js" */),
  "component---src-pages-all-time-stats-messi-barcelona-stats-js": () => import("./../../../src/pages/all-time-stats/messi-barcelona-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-messi-barcelona-stats-js" */),
  "component---src-pages-all-time-stats-messi-inter-miami-stats-js": () => import("./../../../src/pages/all-time-stats/messi-inter-miami-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-messi-inter-miami-stats-js" */),
  "component---src-pages-all-time-stats-messi-psg-stats-js": () => import("./../../../src/pages/all-time-stats/messi-psg-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-messi-psg-stats-js" */),
  "component---src-pages-all-time-stats-progression-js": () => import("./../../../src/pages/all-time-stats/progression.js" /* webpackChunkName: "component---src-pages-all-time-stats-progression-js" */),
  "component---src-pages-all-time-stats-ronaldo-al-nassr-stats-js": () => import("./../../../src/pages/all-time-stats/ronaldo-al-nassr-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-ronaldo-al-nassr-stats-js" */),
  "component---src-pages-all-time-stats-ronaldo-juventus-stats-js": () => import("./../../../src/pages/all-time-stats/ronaldo-juventus-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-ronaldo-juventus-stats-js" */),
  "component---src-pages-all-time-stats-ronaldo-manchester-united-stats-js": () => import("./../../../src/pages/all-time-stats/ronaldo-manchester-united-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-ronaldo-manchester-united-stats-js" */),
  "component---src-pages-all-time-stats-ronaldo-real-madrid-stats-js": () => import("./../../../src/pages/all-time-stats/ronaldo-real-madrid-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-ronaldo-real-madrid-stats-js" */),
  "component---src-pages-all-time-stats-ronaldo-sporting-cp-stats-js": () => import("./../../../src/pages/all-time-stats/ronaldo-sporting-cp-stats.js" /* webpackChunkName: "component---src-pages-all-time-stats-ronaldo-sporting-cp-stats-js" */),
  "component---src-pages-all-time-stats-stats-by-age-cumulative-js": () => import("./../../../src/pages/all-time-stats/stats-by-age-cumulative.js" /* webpackChunkName: "component---src-pages-all-time-stats-stats-by-age-cumulative-js" */),
  "component---src-pages-all-time-stats-stats-by-age-js": () => import("./../../../src/pages/all-time-stats/stats-by-age.js" /* webpackChunkName: "component---src-pages-all-time-stats-stats-by-age-js" */),
  "component---src-pages-all-time-stats-uefa-club-rankings-js": () => import("./../../../src/pages/all-time-stats/uefa-club-rankings.js" /* webpackChunkName: "component---src-pages-all-time-stats-uefa-club-rankings-js" */),
  "component---src-pages-all-time-stats-vs-bottom-10-js": () => import("./../../../src/pages/all-time-stats/vs-bottom-10.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-bottom-10-js" */),
  "component---src-pages-all-time-stats-vs-bottom-3-js": () => import("./../../../src/pages/all-time-stats/vs-bottom-3.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-bottom-3-js" */),
  "component---src-pages-all-time-stats-vs-english-teams-js": () => import("./../../../src/pages/all-time-stats/vs-english-teams.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-english-teams-js" */),
  "component---src-pages-all-time-stats-vs-french-teams-js": () => import("./../../../src/pages/all-time-stats/vs-french-teams.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-french-teams-js" */),
  "component---src-pages-all-time-stats-vs-german-teams-js": () => import("./../../../src/pages/all-time-stats/vs-german-teams.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-german-teams-js" */),
  "component---src-pages-all-time-stats-vs-italian-teams-js": () => import("./../../../src/pages/all-time-stats/vs-italian-teams.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-italian-teams-js" */),
  "component---src-pages-all-time-stats-vs-spanish-teams-js": () => import("./../../../src/pages/all-time-stats/vs-spanish-teams.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-spanish-teams-js" */),
  "component---src-pages-all-time-stats-vs-top-10-js": () => import("./../../../src/pages/all-time-stats/vs-top-10.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-top-10-js" */),
  "component---src-pages-all-time-stats-vs-top-3-js": () => import("./../../../src/pages/all-time-stats/vs-top-3.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-top-3-js" */),
  "component---src-pages-all-time-stats-vs-top-5-js": () => import("./../../../src/pages/all-time-stats/vs-top-5.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-top-5-js" */),
  "component---src-pages-all-time-stats-vs-top-sides-js": () => import("./../../../src/pages/all-time-stats/vs-top-sides.js" /* webpackChunkName: "component---src-pages-all-time-stats-vs-top-sides-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-calendar-year-stats-js": () => import("./../../../src/pages/calendar-year-stats.js" /* webpackChunkName: "component---src-pages-calendar-year-stats-js" */),
  "component---src-pages-club-stats-champions-league-js": () => import("./../../../src/pages/club-stats/champions-league.js" /* webpackChunkName: "component---src-pages-club-stats-champions-league-js" */),
  "component---src-pages-club-stats-el-clasico-js": () => import("./../../../src/pages/club-stats/el-clasico.js" /* webpackChunkName: "component---src-pages-club-stats-el-clasico-js" */),
  "component---src-pages-club-stats-js": () => import("./../../../src/pages/club-stats.js" /* webpackChunkName: "component---src-pages-club-stats-js" */),
  "component---src-pages-club-stats-messi-vs-ronaldo-in-spain-js": () => import("./../../../src/pages/club-stats/messi-vs-ronaldo-in-spain.js" /* webpackChunkName: "component---src-pages-club-stats-messi-vs-ronaldo-in-spain-js" */),
  "component---src-pages-detailed-stats-aerial-duels-js": () => import("./../../../src/pages/detailed-stats/aerial-duels.js" /* webpackChunkName: "component---src-pages-detailed-stats-aerial-duels-js" */),
  "component---src-pages-detailed-stats-all-assists-js": () => import("./../../../src/pages/detailed-stats/all-assists.js" /* webpackChunkName: "component---src-pages-detailed-stats-all-assists-js" */),
  "component---src-pages-detailed-stats-all-goals-including-youth-js": () => import("./../../../src/pages/detailed-stats/all-goals-including-youth.js" /* webpackChunkName: "component---src-pages-detailed-stats-all-goals-including-youth-js" */),
  "component---src-pages-detailed-stats-average-match-ratings-js": () => import("./../../../src/pages/detailed-stats/average-match-ratings.js" /* webpackChunkName: "component---src-pages-detailed-stats-average-match-ratings-js" */),
  "component---src-pages-detailed-stats-big-chances-created-js": () => import("./../../../src/pages/detailed-stats/big-chances-created.js" /* webpackChunkName: "component---src-pages-detailed-stats-big-chances-created-js" */),
  "component---src-pages-detailed-stats-defensive-stats-js": () => import("./../../../src/pages/detailed-stats/defensive-stats.js" /* webpackChunkName: "component---src-pages-detailed-stats-defensive-stats-js" */),
  "component---src-pages-detailed-stats-free-kicks-js": () => import("./../../../src/pages/detailed-stats/free-kicks.js" /* webpackChunkName: "component---src-pages-detailed-stats-free-kicks-js" */),
  "component---src-pages-detailed-stats-goal-contributions-js": () => import("./../../../src/pages/detailed-stats/goal-contributions.js" /* webpackChunkName: "component---src-pages-detailed-stats-goal-contributions-js" */),
  "component---src-pages-detailed-stats-goals-js": () => import("./../../../src/pages/detailed-stats/goals.js" /* webpackChunkName: "component---src-pages-detailed-stats-goals-js" */),
  "component---src-pages-detailed-stats-goals-without-penalties-js": () => import("./../../../src/pages/detailed-stats/goals-without-penalties.js" /* webpackChunkName: "component---src-pages-detailed-stats-goals-without-penalties-js" */),
  "component---src-pages-detailed-stats-hat-tricks-js": () => import("./../../../src/pages/detailed-stats/hat-tricks.js" /* webpackChunkName: "component---src-pages-detailed-stats-hat-tricks-js" */),
  "component---src-pages-detailed-stats-js": () => import("./../../../src/pages/detailed-stats.js" /* webpackChunkName: "component---src-pages-detailed-stats-js" */),
  "component---src-pages-detailed-stats-key-passes-js": () => import("./../../../src/pages/detailed-stats/key-passes.js" /* webpackChunkName: "component---src-pages-detailed-stats-key-passes-js" */),
  "component---src-pages-detailed-stats-man-of-the-match-awards-js": () => import("./../../../src/pages/detailed-stats/man-of-the-match-awards.js" /* webpackChunkName: "component---src-pages-detailed-stats-man-of-the-match-awards-js" */),
  "component---src-pages-detailed-stats-match-winning-goals-js": () => import("./../../../src/pages/detailed-stats/match-winning-goals.js" /* webpackChunkName: "component---src-pages-detailed-stats-match-winning-goals-js" */),
  "component---src-pages-detailed-stats-opta-assists-js": () => import("./../../../src/pages/detailed-stats/opta-assists.js" /* webpackChunkName: "component---src-pages-detailed-stats-opta-assists-js" */),
  "component---src-pages-detailed-stats-outside-box-goals-js": () => import("./../../../src/pages/detailed-stats/outside-box-goals.js" /* webpackChunkName: "component---src-pages-detailed-stats-outside-box-goals-js" */),
  "component---src-pages-detailed-stats-penalties-js": () => import("./../../../src/pages/detailed-stats/penalties.js" /* webpackChunkName: "component---src-pages-detailed-stats-penalties-js" */),
  "component---src-pages-detailed-stats-points-contribution-js": () => import("./../../../src/pages/detailed-stats/points-contribution.js" /* webpackChunkName: "component---src-pages-detailed-stats-points-contribution-js" */),
  "component---src-pages-detailed-stats-record-in-finals-js": () => import("./../../../src/pages/detailed-stats/record-in-finals.js" /* webpackChunkName: "component---src-pages-detailed-stats-record-in-finals-js" */),
  "component---src-pages-detailed-stats-shot-conversion-js": () => import("./../../../src/pages/detailed-stats/shot-conversion.js" /* webpackChunkName: "component---src-pages-detailed-stats-shot-conversion-js" */),
  "component---src-pages-detailed-stats-successful-dribbles-js": () => import("./../../../src/pages/detailed-stats/successful-dribbles.js" /* webpackChunkName: "component---src-pages-detailed-stats-successful-dribbles-js" */),
  "component---src-pages-detailed-stats-throughballs-js": () => import("./../../../src/pages/detailed-stats/throughballs.js" /* webpackChunkName: "component---src-pages-detailed-stats-throughballs-js" */),
  "component---src-pages-detailed-stats-xa-expected-assists-js": () => import("./../../../src/pages/detailed-stats/xa-expected-assists.js" /* webpackChunkName: "component---src-pages-detailed-stats-xa-expected-assists-js" */),
  "component---src-pages-detailed-stats-xg-expected-goals-js": () => import("./../../../src/pages/detailed-stats/xg-expected-goals.js" /* webpackChunkName: "component---src-pages-detailed-stats-xg-expected-goals-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-honours-and-achievements-js": () => import("./../../../src/pages/honours-and-achievements.js" /* webpackChunkName: "component---src-pages-honours-and-achievements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-awards-js": () => import("./../../../src/pages/individual-awards.js" /* webpackChunkName: "component---src-pages-individual-awards-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-international-stats-copa-euros-stats-js": () => import("./../../../src/pages/international-stats/copa-euros-stats.js" /* webpackChunkName: "component---src-pages-international-stats-copa-euros-stats-js" */),
  "component---src-pages-international-stats-fifa-rankings-competitive-js": () => import("./../../../src/pages/international-stats/fifa-rankings-competitive.js" /* webpackChunkName: "component---src-pages-international-stats-fifa-rankings-competitive-js" */),
  "component---src-pages-international-stats-fifa-rankings-js": () => import("./../../../src/pages/international-stats/fifa-rankings.js" /* webpackChunkName: "component---src-pages-international-stats-fifa-rankings-js" */),
  "component---src-pages-international-stats-js": () => import("./../../../src/pages/international-stats.js" /* webpackChunkName: "component---src-pages-international-stats-js" */),
  "component---src-pages-international-stats-world-cup-stats-js": () => import("./../../../src/pages/international-stats/world-cup-stats.js" /* webpackChunkName: "component---src-pages-international-stats-world-cup-stats-js" */),
  "component---src-pages-match-histories-js": () => import("./../../../src/pages/match-histories.js" /* webpackChunkName: "component---src-pages-match-histories-js" */),
  "component---src-pages-match-histories-messi-match-history-js": () => import("./../../../src/pages/match-histories/messi-match-history.js" /* webpackChunkName: "component---src-pages-match-histories-messi-match-history-js" */),
  "component---src-pages-match-histories-ronaldo-match-history-js": () => import("./../../../src/pages/match-histories/ronaldo-match-history.js" /* webpackChunkName: "component---src-pages-match-histories-ronaldo-match-history-js" */),
  "component---src-pages-myths-js": () => import("./../../../src/pages/myths.js" /* webpackChunkName: "component---src-pages-myths-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-records-js": () => import("./../../../src/pages/records.js" /* webpackChunkName: "component---src-pages-records-js" */),
  "component---src-pages-vote-and-discuss-js": () => import("./../../../src/pages/vote-and-discuss.js" /* webpackChunkName: "component---src-pages-vote-and-discuss-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-cal-year-season-js": () => import("./../../../src/templates/cal-year-season.js" /* webpackChunkName: "component---src-templates-cal-year-season-js" */),
  "component---src-templates-club-stats-season-js": () => import("./../../../src/templates/club-stats-season.js" /* webpackChunkName: "component---src-templates-club-stats-season-js" */),
  "component---src-templates-int-stats-season-js": () => import("./../../../src/templates/int-stats-season.js" /* webpackChunkName: "component---src-templates-int-stats-season-js" */)
}

